import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import IntlMessages from '@iso/components/utility/intlMessages';
import Alert from '@iso/components/Feedback/Alert';
import Spin from '../General/Spin.styles';
//Nuevo
import { Form, Input, Button } from 'antd';
import notifications from '@iso/components/Feedback/Notification';
import AuthServices from '../../services/apiLogin/auth.services';
import ReCAPTCHA from 'react-google-recaptcha';
import { GENERAL } from '../../env-config';

export default function Login(props) {
  //Nuevo
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.createRef();

  useEffect(() => {
    recaptchaRef.current.reset();
    return () => {
      // clean up
    };
  });

  /**
   * Funcion que hace la peticion al serivio de login
   * @param {object} values Datos del formulario de login
   */
  const onFinish = async (values) => {
    //Si no da permisos de ubicacion mostrar error
    if (props.latitude === null || props.longitude === null) {
      console.log('error');
      notifications['error']({
        message: 'Permisos necesarios',
        description: 'Para continuar necesitas proporionar tu ubicación.',
      });
      return;
    }
    const valueCaptcha = await recaptchaRef.current.executeAsync();
    setLoading(true);
    if (valueCaptcha) {
      let data = values;
      data.security_hash = valueCaptcha;
      data.latitude = String(props.latitude);
      data.longitude = String(props.longitude);
      data.ip = props.ip;
      data.email = values.email;
      await AuthServices.login(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              props.onLogin(response, values.email);
            } else {
              notifications['error']({
                message: 'Datos incorrectos',
                description: 'El email o el password son incorrectos.',
              });
            }
          } else {
            notifications['error']({
              message: 'Datos incorrectos',
              description: 'El email o el password son incorrectos.',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            notifications['error']({
              message: 'Datos incorrectos',
              description: error.response.data.data.message,
            });
          } else {
            notifications['error']({
              message: 'Error',
              description: 'Ha ocurrido un error, contacte con soporte.',
            });
          }
        });
    } else {
      console.log(valueCaptcha);
      notifications['error']({
        message: 'Error',
        description: 'Ha ocurrido un error, vuelve a intentarlo.',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="isoSignInForm">
        <Form
          form={form}
          name="login"
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
        >
          <Form.Item
            label={<IntlMessages id="login.email" />}
            name="email"
            rules={[
              {
                type: 'email',
                message: <IntlMessages id="login.invalid.email" />,
              },
              {
                required: true,
                message: <IntlMessages id="login.required.email" />,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<IntlMessages id="login.password" />}
            name="password"
            rules={[
              {
                required: true,
                message: <IntlMessages id="login.required.password" />,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            {props.viewMessageLocation === true && (
              <Alert
                message={<IntlMessages id="feedback.alert.warningTips" />}
                description={<IntlMessages id="feedback.alert.location" />}
                type="warning"
                showIcon
              />
            )}
            {props.loadingInfo === true && (
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                <Spin size="large" />
              </div>
            )}
            {props.ip !== null && (
              <Button type="primary" htmlType="submit" loading={loading}>
                <IntlMessages id="login.next" />
              </Button>
            )}
          </Form.Item>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={GENERAL.SITE_KEY_RECAPTCHA}
            size="invisible"
          />
        </Form>
      </div>
      <div className="isoCenterComponent isoHelperWrapper">
        <Link href="/forgotpassword">
          <div className="isoForgotPass">
            <a>
              <IntlMessages id="page.forgetPassSubTitle" />
            </a>
          </div>
        </Link>
      </div>
    </>
  );
}
