import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useDispatch } from 'react-redux';
import authActions from '../authentication/actions';
import SignInStyleWrapper from '../styled/SignIn.styles';

//Nuevo
import LocalStorageServices from '../services/localStorage.services';
import logosidebar from '@iso/assets/images/logos/logo.png';
import Login from '../containers/Login/Login';
import ValidatePin from '../containers/Login/ValidatePin';
import AuthServices from '../services/apiLogin/auth.services';
import notifications from '@iso/components/Feedback/Notification';
import publicIp from "public-ip";
import Permissions from '../utils/permissions';


const { login } = authActions;
export default function SignInPage(props) {
  //Nuevo
  const dispatch = useDispatch();
  const [loginValidate, setLoginValidate] = useState(false);
  const [dataLogin, setDataLogin] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [viewMessagaLocation, setViewMessagaLocation] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [IPPublic, setIPPublic] = useState(null)

  useEffect(() => {

    async function fetchData() {

      //Obtener la geolocalizacion
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setViewMessagaLocation(false);
        publicIp.v4()
          .then((result) => {
            setLoadingInfo(false);
            setIPPublic(result);
          });
      },
        () => {
          notifications['error']({
            message: 'Permisos necesarios',
            description: 'Para continuar necesitas proporionar tu ubicación.',
          });
        }
      );
    }


    let data_token = LocalStorageServices.getDataToken();
    let current_user = LocalStorageServices.getCurrentUser();
    if (data_token !== null && current_user !== null) {
      //send valid token
      let data = {
        refresh_token: data_token.refresh_token,
        email: current_user.email
      }
      AuthServices.refreshToken(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            LocalStorageServices.setDataToken(response.data);
            window.location.href = "/dashboard";
          } else {
            LocalStorageServices.deleteAllData();
          }
        })
        .catch((error) => {
          //console.log(error);
          LocalStorageServices.deleteAllData();
        })
    }

    fetchData();
    return () => {
      // clean up
    };
  }, []);

  /**
   * Funcion que recibe los datos del componente hijo Login
   * @param {object} response El parametro se recibe desde el componente hijo
   */
  const setDataStep1 = (response, email) => {
    if (response.status === 200) {
      setLoginValidate(true);
      let data = response.data.data;
      data.email = email
      setDataLogin(data);
    }
  }
  /**
   * Funcion que recibe los datos del componente hijo ValidatePin
   * @param {object} response El parametro se recibe desde el componente hijo
   */
  const validatePin = async (response) => {
    LocalStorageServices.setDataToken(response);
    let data_login = dataLogin;
    data_login.id = response.user.id;
    data_login.name = response.user.name;
    data_login.avatar = response.user.avatar;
    data_login.permissions = null;
    LocalStorageServices.setCurrentUser(data_login);
    await Permissions.get();
    dispatch(login(true));
  }

  /**
   * Resetear los states asociados al login
   */
  const resetStateLogin = () => {
    setLoginValidate(false);
    setDataLogin(null);
  }

  return (
    <>
      <Head>
        <title>Login - Admin Hostech - Operador Mayorista de Viajes</title>
      </Head>
      <SignInStyleWrapper className="isoSignInPage">
        <div className="isoLoginContentWrapper">
          <div className="isoLoginContent">
            <div className="isoLogoWrapper">
              <img alt="logo" style={{ width: '130px' }} src={logosidebar} />
            </div>
            {(loginValidate === false) ?
              <Login onLogin={setDataStep1} latitude={latitude} longitude={longitude} ip={IPPublic} viewMessageLocation={viewMessagaLocation} loadingInfo={loadingInfo} />
              :
              <ValidatePin onValidatePin={validatePin} dataLogin={dataLogin} latitude={latitude} longitude={longitude} ip={IPPublic} onResetStateLogin={resetStateLogin} />
            }
          </div>
        </div>
      </SignInStyleWrapper>
    </>
  );
}
