import React, { useState, useEffect } from 'react';

import { THUMBOR } from '../../env-config';
import {ThumborAvatar} from '@iso/components/ThumborImg';
import IntlMessages from '@iso/components/utility/intlMessages';
//Nuevo
import { Form, Button, Row, Col } from 'antd';
import notifications from '@iso/components/Feedback/Notification';
import AuthServices from '../../services/apiLogin/auth.services';
import PinInput from 'react-pin-input'
import basicStyle from '@iso/assets/styles/constants';


export default function ValidatePin(props) {
    //Nuevo
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { rowStyle, colStyle, gutter } = basicStyle;
    //const [pin, setPin] = useState(null);

    useEffect(() => {
        return () => {
            // clean up
        };
    });

    /**
     * Funcion que hace la peticion al serivio para validar el PIN
     * @param {object} values Datos del formulario de PIN
     */
    const onFinish = async (pin) => {
        setLoading(true);
        if (pin === null) {
            notifications['error']({
                message: 'Error de PIN',
                description: 'Ingresa el PIN completo',
            })
            setLoading(false);
            return;
        }
        if (pin.length === 4) {
            let data = {};
            data.pin = parseInt(pin);
            data.email = props.dataLogin.email;
            data.login_token = props.dataLogin.login_token;
            data.ip = props.ip;
            data.latitude = String(props.latitude);
            data.longitude = String(props.longitude);
            await AuthServices.validatePin(data)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.success === true) {
                            props.onValidatePin(response.data.data);
                        } else {
                            notifications['error']({
                                message: 'Error PIN',
                                description: 'El PIN ingresado no es valido',
                            });
                        }
                    } else {
                        notifications['error']({
                            message: 'Error PIN',
                            description: 'El PIN ingresado no es valido',
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        notifications['error']({
                            message: 'PIN incorrecto',
                            description: error.response.data.data.message,
                        });
                    } else {
                        notifications['error']({
                            message: 'Error',
                            description: 'Ha ocurrido un error, contacte con soporte.',
                        });
                    }
                });
        } else {
            notifications['error']({
                message: 'Error PIN',
                description: 'Ingresa el PIN completo',
            });
        }
        setLoading(false);
    };

    /**
     * Funcion para eliminar los states que se asignan cuando el login el correcto
     */
    const resetStateLogin = () => {
        props.onResetStateLogin();
    }

    return (
        <>
            <div className="isoSignInForm">
                <div style={{ textAlign: 'center' }}>
                    <h2><IntlMessages id="login.welcome" /></h2>
                </div>
                {props.dataLogin !== null &&
                    <div style={{ textAlign: 'center' }}>
                        <h3>{props.dataLogin.name}</h3>
                    </div>
                }
                {props.dataLogin !== null &&
                <Row style={rowStyle} justify="center">
                    <Col md={24} sm={24} xs={24} style={{textAlign:'center', margin:'10px'}}>
                        <ThumborAvatar src={props.dataLogin.avatar} size={100} width={100} height={100} config={THUMBOR} crop={true} />
                    </Col>
                </Row>
                }
                <Row style={rowStyle} gutter={gutter} justify="start">
                    <Col md={24} sm={24} xs={24} style={colStyle}>
                        <PinInput
                            length={4}
                            initialValue=""
                            onComplete={(value, index) => {
                                onFinish(value);
                                //setPin(value);
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: '0px', textAlign: 'center' }}
                        />
                    </Col>
                    <Col md={24} sm={24} xs={24} style={colStyle}>
                        <Button onClick={onFinish} type="primary" htmlType="submit" loading={loading}>&nbsp;
                            <IntlMessages id="login.enter" />
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="isoCenterComponent isoHelperWrapper">
                <a onClick={resetStateLogin}>
                    <IntlMessages id="login.requesNewPin" />
                </a>
            </div>
        </>

    );
}